// src/utils/amazonUtils.js

const AFFILIATE_TAG = 'giftlister02-20'; // Replace with your Amazon affiliate tag

export const transformAmazonLink = (url) => {
  try {
    // Return original URL if it's not an Amazon link
    if (!url.includes('amazon.')) {
      return url;
    }

    const urlObj = new URL(url);
    
    // Handle different Amazon domains
    if (!urlObj.hostname.includes('amazon.')) {
      return url;
    }

    // Remove existing tags if present
    const searchParams = new URLSearchParams(urlObj.search);
    searchParams.delete('tag');
    
    // Extract the product ID (ASIN)
    let asin = '';
    
    // Try to get ASIN from URL path (common format: /dp/ASIN or /gp/product/ASIN)
    const dpMatch = urlObj.pathname.match(/\/dp\/([A-Z0-9]{10})/);
    const gpMatch = urlObj.pathname.match(/\/gp\/product\/([A-Z0-9]{10})/);
    
    if (dpMatch) {
      asin = dpMatch[1];
    } else if (gpMatch) {
      asin = gpMatch[1];
    }

    // If we found an ASIN, create a clean URL
    if (asin) {
      // Create a clean Amazon URL with just the ASIN
      return `https://www.amazon.com/dp/${asin}?tag=${AFFILIATE_TAG}`;
    }

    // If we couldn't find an ASIN, just add the tag to the existing URL
    searchParams.append('tag', AFFILIATE_TAG);
    urlObj.search = searchParams.toString();
    
    return urlObj.toString();
  } catch (error) {
    console.error('Error transforming Amazon link:', error);
    return url; // Return original URL if there's any error
  }
};

export const validateAndFormatUrl = (url) => {
  if (!url) return '';
  
  try {
    // Add https:// if no protocol is specified
    if (!url.match(/^https?:\/\//i)) {
      url = `https://${url}`;
    }

    // If it's an Amazon URL, transform it
    if (url.includes('amazon.')) {
      return transformAmazonLink(url);
    }

    return url;
  } catch (error) {
    console.error('Error formatting URL:', error);
    return url;
  }
};

// Additional utility to check if a link is an Amazon link
export const isAmazonLink = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname.includes('amazon.');
  } catch {
    return false;
  }
};