import * as React from "react";
import { cn } from "../../lib/utils";

const AlertDialog = React.forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 flex items-center justify-center bg-black/50",
      className
    )}
    {...props}
  >
    {children}
  </div>
));
AlertDialog.displayName = "AlertDialog";

const AlertDialogContent = React.forwardRef(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "relative bg-white w-full max-w-lg p-6 rounded-lg shadow-lg",
      "mx-4 max-h-[85vh] overflow-y-auto",
      className
    )}
    {...props}
  >
    {children}
  </div>
));
AlertDialogContent.displayName = "AlertDialogContent";

const AlertDialogHeader = ({ className, ...props }) => (
  <div
    className={cn("mb-4 text-center", className)}
    {...props}
  />
);
AlertDialogHeader.displayName = "AlertDialogHeader";

const AlertDialogTitle = ({ className, ...props }) => (
  <h2
    className={cn("text-xl font-semibold", className)}
    {...props}
  />
);
AlertDialogTitle.displayName = "AlertDialogTitle";

const AlertDialogDescription = ({ className, ...props }) => (
  <p
    className={cn("mt-2 text-sm text-gray-500", className)}
    {...props}
  />
);
AlertDialogDescription.displayName = "AlertDialogDescription";

const AlertDialogFooter = ({ className, ...props }) => (
  <div
    className={cn("mt-6 flex justify-end space-x-4", className)}
    {...props}
  />
);
AlertDialogFooter.displayName = "AlertDialogFooter";

const AlertDialogAction = ({ className, ...props }) => (
  <button
    className={cn(
      "inline-flex items-center justify-center px-4 py-2 rounded-md",
      "font-medium transition-colors focus:outline-none focus:ring-2",
      "focus:ring-offset-2 disabled:opacity-50",
      className
    )}
    {...props}
  />
);
AlertDialogAction.displayName = "AlertDialogAction";

const AlertDialogCancel = ({ className, ...props }) => (
  <button
    className={cn(
      "inline-flex items-center justify-center px-4 py-2 rounded-md",
      "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50",
      "font-medium transition-colors focus:outline-none focus:ring-2",
      "focus:ring-offset-2 disabled:opacity-50",
      className
    )}
    {...props}
  />
);
AlertDialogCancel.displayName = "AlertDialogCancel";

export {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogAction,
  AlertDialogCancel,
};