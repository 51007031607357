// src/services/firebase/db.js
import { db } from './firebaseConfig';
import {
    collection,
    doc,
    addDoc,
    getDocs,
    getDoc,
    setDoc,
    query,
    where,
    serverTimestamp,
    updateDoc,
    deleteDoc,
    arrayUnion,
    arrayRemove
} from 'firebase/firestore';

// Create a new list
export const createList = async (ownerId, title) => {
    try {
        const listRef = collection(db, 'lists');
        const docRef = await addDoc(listRef, {
            ownerId,
            title,
            createdAt: serverTimestamp(),
            sharedWith: [ownerId], // Include the owner in shared access
        });
        console.log('List created with ID:', docRef.id);
        return docRef.id;
    } catch (error) {
        console.error('Error creating list:', error);
    }
};

// Add an item to a list
export const addItemToList = async (listId, item) => {
    try {
        const itemsRef = collection(db, `lists/${listId}/items`);
        await addDoc(itemsRef, {
            ...item,
            isClaimed: false,
            claimedBy: null,
        });
        console.log('Item added to list');
    } catch (error) {
        console.error('Error adding item to list:', error);
    }
};

// Add a surprise gift
export const addSurpriseGift = async (listId, gift) => {
    try {
        const giftsRef = collection(db, `lists/${listId}/surpriseGifts`);
        await addDoc(giftsRef, gift);
        console.log('Surprise gift added');
    } catch (error) {
        console.error('Error adding surprise gift:', error);
    }
};

// Fetch all lists shared with the current user
export const fetchSharedLists = async (userId) => {
    try {
      // First get all lists owned by the user
      const q = query(
        collection(db, 'lists'),
        where('ownerId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      
      // Then filter out any deleted or archived lists
      return querySnapshot.docs
        .map((doc) => ({ 
          id: doc.id,
          ...doc.data() 
        }))
        .filter(list => list.status !== 'deleted' && list.status !== 'permanently_deleted');
    } catch (error) {
      console.error('Error fetching shared lists:', error);
      return [];
    }
  };

  export const fetchFriendsLists = async (userId) => {
    try {
      const q = query(
        collection(db, 'lists'),
        where('sharedWith', 'array-contains', userId)  // This ensures the user is in the sharedWith array
      );
      const querySnapshot = await getDocs(q);
      // Filter out lists where the user is the owner
      return querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter((list) => list.ownerId !== userId); // Remove lists owned by the user
    } catch (error) {
      console.error('Error fetching friends lists:', error);
      return [];
    }
  };

// Fetch items in a specific list
export const fetchListItems = async (listId) => {
    try {
        const itemsRef = collection(db, `lists/${listId}/items`);
        const querySnapshot = await getDocs(itemsRef);
        return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error('Error fetching list items:', error);
    }
};

// src/services/firebase/db.js
export const generateShareableLink = (listId) => {
    const baseUrl = `${window.location.origin}/list`;
    return `${baseUrl}/${listId}`;
};

export const addSharedAccess = async (listId, userId) => {
    try {
      const listRef = doc(db, 'lists', listId);
      const listDoc = await getDoc(listRef);
      
      if (!listDoc.exists()) {
        throw new Error('List not found');
      }
  
      const listData = listDoc.data();
      const currentSharedWith = listData.sharedWith || [];
      
      // Only add if not already in the array
      if (!currentSharedWith.includes(userId)) {
        const updatedSharedWith = [...currentSharedWith, userId];
        await updateDoc(listRef, {
          sharedWith: updatedSharedWith,
          updatedAt: serverTimestamp()
        });
      }
  
      return true;
    } catch (error) {
      console.error('Error adding shared access:', error);
      throw error;
    }
  };

export const claimListItem = async (listId, itemId, userId) => {
    try {
      // Get the current list
      const listRef = doc(db, 'lists', listId);
      const listDoc = await getDoc(listRef);
      
      if (!listDoc.exists()) {
        throw new Error('List not found');
      }
  
      const listData = listDoc.data();
      const updatedItems = (listData.items || []).map((item, index) => {
        if (item.id === itemId || String(index) === itemId) {
          return {
            ...item,
            claimedBy: userId,
            claimedAt: new Date().toISOString()
          };
        }
        return item;
      });
  
      // Update the entire list document
      await updateDoc(listRef, {
        items: updatedItems
      });
  
      return true;
    } catch (error) {
      console.error('Error claiming item:', error);
      throw error;
    }
  };
  
  export const unclaimListItem = async (listId, itemId) => {
    try {
      // Get the current list
      const listRef = doc(db, 'lists', listId);
      const listDoc = await getDoc(listRef);
      
      if (!listDoc.exists()) {
        throw new Error('List not found');
      }
  
      const listData = listDoc.data();
      const updatedItems = (listData.items || []).map((item, index) => {
        if (item.id === itemId || String(index) === itemId) {
          const { claimedBy, claimedAt, ...restItem } = item;
          return restItem;
        }
        return item;
      });
  
      // Update the entire list document
      await updateDoc(listRef, {
        items: updatedItems
      });
  
      return true;
    } catch (error) {
      console.error('Error unclaiming item:', error);
      throw error;
    }
  };

  export const softDeleteList = async (listId, userId) => {
    try {
      const listRef = doc(db, 'lists', listId);
      const listDoc = await getDoc(listRef);
      
      if (!listDoc.exists()) {
        throw new Error('List not found');
      }
  
      const listData = listDoc.data();
      // Check if the list has ever been shared (either currently or previously)
      const hasBeenShared = (listData.sharedWith?.length || 0) > 1 || 
                           Object.keys(listData.removedShares || {}).length > 0;
  
      // If the list has ever been shared, archive it
      if (hasBeenShared) {
        await updateDoc(listRef, {
          status: 'deleted',
          deletedAt: serverTimestamp(),
          archivedBy: userId
        });
        return { success: true, archived: true };
      } else {
        // Only if the list has never been shared with anyone, permanently delete it
        await updateDoc(listRef, {
          status: 'permanently_deleted',
          deletedAt: serverTimestamp()
        });
        return { success: true, archived: false };
      }
    } catch (error) {
      console.error('Error deleting list:', error);
      throw error;
    }
  };
  
  export const removeSharedAccess = async (listId, userId) => {
    try {
      const listRef = doc(db, 'lists', listId);
      const listDoc = await getDoc(listRef);
      
      if (!listDoc.exists()) {
        throw new Error('List not found');
      }
  
      // Add to user's archived shared lists
      await updateDoc(listRef, {
        sharedWith: arrayRemove(userId),
        [`removedShares.${userId}`]: {
          removedAt: serverTimestamp(),
          archived: true
        }
      });
      
      return true;
    } catch (error) {
      console.error('Error removing shared access:', error);
      throw error;
    }
  };
  
  export const fetchArchivedLists = async (userId) => {
    try {
      // Query both deleted and permanently_deleted lists owned by the user
      const q = query(
        collection(db, 'lists'),
        where('ownerId', '==', userId),
        where('status', '==', 'deleted')  // Only fetch deleted status for archives
      );
      
      const querySnapshot = await getDocs(q);
      const archivedLists = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      console.log('Fetched archived lists:', archivedLists); // Debug log
      return archivedLists;
    } catch (error) {
      console.error('Error fetching archived lists:', error);
      throw error; // Throw error to handle it in the UI
    }
  };
  
  export const fetchSharedArchivedLists = async (userId) => {
    try {
      // First get all lists that contain the user in removedShares
      const q = query(
        collection(db, 'lists'),
        where(`removedShares.${userId}.archived`, '==', true)
      );
      
      const querySnapshot = await getDocs(q);
      const archivedLists = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      console.log('Fetched shared archived lists:', archivedLists); // Debug log
      return archivedLists;
    } catch (error) {
      console.error('Error fetching shared archived lists:', error);
      throw error;
    }
  };
