import React from 'react';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../components/ui/alert-dialog';
import { X } from 'lucide-react';

const DeleteListDialog = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  listTitle, 
  sharedCount,
  isOwner 
}) => {
  if (!isOpen) return null;

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete "{listTitle}"?</AlertDialogTitle>
          <AlertDialogDescription>
            {isOwner ? (
              sharedCount > 0 ? (
                <>
                  <br />
                  This list is currently shared with {sharedCount} {sharedCount === 1 ? 'person' : 'people'}. 
                  <br />
                  <br />
                  The list will be archived and marked as deleted for all users.
                </>
              ) : (
                <>
                <br />
                This list will be <i> permanently </i> deleted.
                </>
              )
            ) : (
              <>
              <br /> 
              This will remove the list from your <b>Shared With Me</b> section.   
              <br /> 
              <br /> 
              <i> You can still find it in your <b>Shared Archive</b>. </i>
              </>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <AlertDialogAction 
            onClick={onConfirm}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteListDialog;