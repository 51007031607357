import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Gift, LogIn } from 'lucide-react';
import { addSharedAccess } from '../services/firebase/db';

const PublicListView = ({ list, ownerData }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleAddToLists = async () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      await addSharedAccess(list.id, currentUser.uid);
      window.location.reload();
    } catch (error) {
      console.error('Error adding list to shared lists:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-8">
      <div className="bg-white rounded-xl shadow-sm p-6">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            {list.title}
          </h1>
          <p className="text-lg text-gray-600 mb-2">
            by {ownerData?.firstName} {ownerData?.lastName}
          </p>
          <div className="flex items-center justify-center text-gray-500">
            <Gift className="h-5 w-5 mr-2" />
            <span>{list.items?.length || 0} Items on List</span>
          </div>
        </div>

        {/* Content with Blurred Overlay */}
        <div className="mb-12 px-4 relative">
          {/* Lorem Ipsum Text */}
          <div className="text-base text-gray-800">
            <p>
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec interdum nibh. Mauris euismod, nisi a facilisis lacinia, quam erat accumsan justo, vitae placerat sapien neque et sapien. Integer eu nunc ac nisl fermentum volutpat. Sed at posuere libero, nec facilisis odio.
            </p>
            <br />
            <p>
              - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec interdum nibh. Mauris euismod, nisi a facilisis lacinia, quam erat accumsan justo, vitae placerat sapien neque et sapien. Integer eu nunc ac nisl fermentum volutpat. Sed at posuere libero, nec facilisis odio.
            </p>
            <br />
            <p>
              Phasellus vel sem at orci ultricies cursus vel nec mauris. Vivamus ut velit et elit pellentesque vehicula. Donec sollicitudin, justo at ullamcorper ullamcorper, tortor orci egestas massa, ut ullamcorper ligula lacus in justo. Nunc in libero ac lacus laoreet consectetur.
            </p>
            <br />
          </div>

          {/* Blurred Overlay */}
          <div className="absolute inset-0 bg-white/50 backdrop-blur-sm" />
        </div>

        {/* Action Button */}
        <div className="text-center">
          <button
            onClick={handleAddToLists}
            className="inline-flex items-center justify-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
          >
            {!currentUser && <LogIn className="h-5 w-5 mr-2" />}
            {currentUser
              ? "Add to Family and Friend's Lists"
              : 'Sign Up to View List'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublicListView;
