import React, { useEffect, useState } from 'react';
import { addSurpriseGift, fetchListItems } from '../services/firebase';
import { useParams } from 'react-router-dom';

const SurpriseGifts = () => {
    const { listId } = useParams();
    const [surpriseGifts, setSurpriseGifts] = useState([]);
    const [newGift, setNewGift] = useState({ title: '', description: '' });

    useEffect(() => {
        const fetchGifts = async () => {
            const gifts = await fetchListItems(listId, 'surpriseGifts');
            setSurpriseGifts(gifts);
        };

        fetchGifts();
    }, [listId]);

    const handleAddGift = async (e) => {
        e.preventDefault();
        if (!newGift.title) return;

        await addSurpriseGift(listId, newGift);
        setNewGift({ title: '', description: '' });
        const updatedGifts = await fetchListItems(listId, 'surpriseGifts');
        setSurpriseGifts(updatedGifts);
    };

    return (
        <div>
            <h2>Surprise Gifts</h2>
            <ul>
                {surpriseGifts.map((gift) => (
                    <li key={gift.id}>
                        <strong>{gift.title}</strong>: {gift.description}
                    </li>
                ))}
            </ul>
            <form onSubmit={handleAddGift}>
                <input
                    type="text"
                    placeholder="Gift Title"
                    value={newGift.title}
                    onChange={(e) => setNewGift({ ...newGift, title: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Description"
                    value={newGift.description}
                    onChange={(e) => setNewGift({ ...newGift, description: e.target.value })}
                />
                <button type="submit">Add Surprise Gift</button>
            </form>
        </div>
    );
};

export default SurpriseGifts;
