import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { db } from '../services/firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { Copy, Edit, Gift, ExternalLink } from 'lucide-react';
import PublicListView from './PublicListView';
import SharedListItem from './SharedListItem';

const List = () => {
  const { currentUser } = useAuth();
  const { listId } = useParams();
  const navigate = useNavigate();
  
  const [list, setList] = useState(null);
  const [ownerData, setOwnerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    const fetchOwnerData = async (ownerId) => {
      const ownerDoc = await getDoc(doc(db, 'users', ownerId));
      if (ownerDoc.exists()) {
        setOwnerData(ownerDoc.data());
      }
    };

    const unsubscribe = onSnapshot(
      doc(db, 'lists', listId),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const listData = { ...docSnapshot.data(), id: listId };
          setList(listData);
          setIsOwner(currentUser && listData.ownerId === currentUser.uid);
          
          if (!ownerData || ownerData.id !== listData.ownerId) {
            fetchOwnerData(listData.ownerId);
          }
          
          setLoading(false);
        } else {
          setError('List not found');
          setLoading(false);
        }
      },
      (error) => {
        console.error('Error fetching list:', error);
        setError('Error fetching list');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [listId, currentUser, ownerData]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/list/${listId}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const renderItems = () => {
    if (!list.items?.length) {
      return (
        <div className="text-center py-8 text-gray-500">
          No items in this list yet
        </div>
      );
    }

    return list.items.map((item, index) => {
      if (isOwner) {
        return (
          <div key={index} className="bg-gray-50 rounded-lg p-4">
            <h3 className="font-medium text-gray-900">{item.name}</h3>
            <div className="mt-1 text-sm text-gray-500">
              <p>Category: {item.category}</p>
              {item.category === 'Clothing' && (
                <p>Size: {item.size} | Color: {item.color}</p>
              )}
              {item.notes && (
                <p className="mt-1 text-gray-600">{item.notes}</p>
              )}
              {item.claimedBy && (
                <p className="mt-1 text-green-600">Item has been claimed</p>
              )}
              {item.link && (
                <a 
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-2 inline-flex items-center text-purple-600 hover:text-purple-700 hover:underline"
                >
                  View Item
                  <ExternalLink className="h-4 w-4 ml-1" />
                </a>
              )}
            </div>
          </div>
        );
      } else {
        return (
          <SharedListItem
            key={index}
            item={item}
            itemId={item.id || String(index)}
            listId={listId}
            currentUserId={currentUser.uid}
          />
        );
      }
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error || !list) {
    return (
      <div className="max-w-4xl mx-auto py-8">
        <div className="bg-red-50 text-red-500 rounded-lg p-4">
          {error || 'List not found'}
        </div>
      </div>
    );
  }

  if (!isOwner && !list.sharedWith?.includes(currentUser?.uid)) {
    return <PublicListView list={list} ownerData={ownerData} />;
  }

  return (
    <div className="max-w-4xl mx-auto py-8">
      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
        <div className="flex justify-between items-start mb-6">
          <div>
            <div className="flex items-baseline gap-3">
              <h1 className="text-2xl font-semibold text-gray-900">{list.title}</h1>
              <span className="text-sm text-gray-500">
                by {ownerData?.firstName} {ownerData?.lastName}
              </span>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Created {new Date(list.createdAt?.toDate()).toLocaleDateString()}
            </p>
            <div className="flex items-center mt-2 text-gray-500">
              <Gift className="h-5 w-5 mr-2" />
              <span>{list.items?.length || 0} Items on List</span>
            </div>
          </div>
          {isOwner && (
            <button
              onClick={() => navigate(`/list/${listId}/edit`)}
              className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              <Edit className="h-4 w-4 mr-2" />
              Edit List
            </button>
          )}
        </div>

        {isOwner && (
          <div className="flex items-center space-x-2 mb-6">
            <input
              type="text"
              readOnly
              value={`${window.location.origin}/list/${listId}`}
              className="flex-1 p-2 bg-gray-50 border border-gray-200 rounded-lg"
            />
            <button
              onClick={handleCopyLink}
              className="inline-flex items-center px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
            >
              <Copy className="h-4 w-4 mr-2" />
              {copied ? 'Copied!' : 'Copy Link'}
            </button>
          </div>
        )}

        <div className="space-y-4">
          {renderItems()}
        </div>
      </div>
    </div>
  );
};

export default List;