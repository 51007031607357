import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Gift, ExternalLink, X, Users } from 'lucide-react';
import { softDeleteList, removeSharedAccess } from '../services/firebase/db';
import DeleteListDialog from './DeleteListDialog';

const ListCard = ({ list, type = 'personal', currentUserId, onDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      if (type === 'personal') {
        const result = await softDeleteList(list.id, currentUserId);
        // Only remove from view if it was permanently deleted
        if (!result.archived) {
          onDelete(list.id);
        }
      } else {
        await removeSharedAccess(list.id, currentUserId);
        onDelete(list.id);
      }
      setShowDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting list:', error);
      alert('Failed to delete list. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  const sharedCount = (list.sharedWith?.length || 0) - 1; // Subtract 1 to exclude owner
  const isDeleted = list.status === 'deleted';

  // Don't show deleted lists in My Lists view
  if (type === 'personal' && isDeleted) {
    return null;
  }

  return (
    <>
      <div className={`
        relative bg-white rounded-xl shadow-sm border border-gray-100 
        hover:shadow-md transition-shadow duration-200
        ${isDeleted ? 'bg-red-50' : ''}
      `}>
        {/* Delete Button */}
        <button
          onClick={() => setShowDeleteDialog(true)}
          className="absolute top-4 right-4 p-1 text-gray-400 hover:text-gray-600"
          disabled={isDeleting}
        >
          <X className="h-5 w-5" />
        </button>

        {/* Shared Count Badge */}
        {type === 'personal' && sharedCount > 0 && (
          <div className="absolute top-4 right-12 px-2 py-1 bg-purple-100 text-purple-600 rounded-full text-xs font-medium flex items-center">
            <Users className="h-3 w-3 mr-1" />
            {sharedCount}
          </div>
        )}

        {/* Deleted Badge - Now right-aligned */}
        {isDeleted && (
          <div className="absolute top-4 right-12 px-2 py-1 bg-red-100 text-red-600 rounded-full text-xs font-medium">
            Deleted
          </div>
        )}

        <div className="p-6">
          <div className="flex items-start justify-between">
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-gray-900">{list.title}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {type === 'personal' ? 'Your List' : 'Shared with you'}
              </p>
            </div>
            {type === 'personal' && !isDeleted && sharedCount === 0 && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                Personal
              </span>
            )}
          </div>
          
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center text-sm text-gray-500">
              <Gift className="h-4 w-4 mr-1" />
              <span>{list.items?.length || 0} items</span>
            </div>
            <Link
              to={`/list/${list.id}`}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-purple-600 hover:text-purple-700"
            >
              View List
              <ExternalLink className="ml-2 h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>

      <DeleteListDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleDelete}
        listTitle={list.title}
        sharedCount={sharedCount}
        isOwner={type === 'personal'}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default ListCard;