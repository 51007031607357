// src/services/firebase/auth.js
import { auth } from './firebaseConfig';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';


const googleProvider = new GoogleAuthProvider();


// Create a user profile
export const createUserProfile = async (userId, email, name) => {
    try {
        const userRef = doc(db, 'users', userId);
        await setDoc(userRef, {
            email,
            name,
            createdAt: serverTimestamp(),
        });
        console.log('User profile created');
    } catch (error) {
        console.error('Error creating user profile:', error);
    }
};

export const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      return result.user;
    } catch (error) {
      throw error;
    }
  };