import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, Gift, Menu, X, PlusCircle, LogOut, Settings } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);

  const menuItems = [
    { name: 'Home', path: '/home', icon: Home },
    { name: 'Create List', path: '/create-list', icon: PlusCircle },
  ];

  const isActive = (path) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="hidden md:block fixed w-full bg-white border-b border-gray-200 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Gift className="h-8 w-8 text-purple-600" />
              <span className="ml-2 text-xl font-semibold">GiftLister</span>
              <div className="hidden md:flex items-center ml-10 space-x-4">
                {menuItems.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => navigate(item.path)}
                    className={`
                      inline-flex items-center px-3 py-2 rounded-md text-sm font-medium
                      ${isActive(item.path)
                        ? 'bg-purple-100 text-purple-700'
                        : 'text-gray-600 hover:bg-gray-50'}
                    `}
                  >
                    <item.icon className="h-5 w-5 mr-2" />
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/settings')}
                className={`
                  inline-flex items-center px-3 py-2 rounded-md text-sm font-medium
                  ${isActive('/settings')
                    ? 'bg-purple-100 text-purple-700'
                    : 'text-gray-600 hover:bg-gray-50'}
                `}
              >
                <Settings className="h-5 w-5" />
              </button>
              <button
                onClick={handleLogout}
                className="inline-flex items-center px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-md"
              >
                <LogOut className="h-5 w-5 mr-2" />
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <div className="md:hidden">
        <div className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 z-50 px-4 h-16 flex items-center justify-between">
          <div className="flex items-center">
            <Gift className="h-8 w-8 text-purple-600" />
            <span className="ml-2 text-xl font-semibold">GiftLister</span>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="p-2 rounded-md text-gray-600 hover:bg-gray-50"
          >
            {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {isOpen && (
          <div className="fixed inset-0 z-40 bg-white pt-16">
            <div className="p-4 space-y-2">
              {menuItems.map((item) => (
                <button
                  key={item.name}
                  onClick={() => {
                    navigate(item.path);
                    setIsOpen(false);
                  }}
                  className={`
                    w-full flex items-center px-4 py-3 text-left rounded-md
                    ${isActive(item.path)
                      ? 'bg-purple-100 text-purple-700'
                      : 'text-gray-600 hover:bg-gray-50'}
                  `}
                >
                  <item.icon className="h-5 w-5 mr-3" />
                  {item.name}
                </button>
              ))}
              <button
                onClick={() => {
                  navigate('/settings');
                  setIsOpen(false);
                }}
                className={`
                  w-full flex items-center px-4 py-3 text-left rounded-md
                  ${isActive('/settings')
                    ? 'bg-purple-100 text-purple-700'
                    : 'text-gray-600 hover:bg-gray-50'}
                `}
              >
                <Settings className="h-5 w-5 mr-3" />
                Settings
              </button>
              <button
                onClick={handleLogout}
                className="w-full flex items-center px-4 py-3 text-left text-gray-600 hover:bg-gray-50 rounded-md"
              >
                <LogOut className="h-5 w-5 mr-3" />
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const MainLayout = ({ children }) => {
  const { currentUser } = useAuth();

  return (
    <div className="w-full min-h-screen bg-gray-50">
      {currentUser && <Navigation />}
      <main className={`${currentUser ? 'pt-20' : ''} max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;