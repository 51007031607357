import React from 'react';
import { ExternalLink, Check } from 'lucide-react';
import { claimListItem, unclaimListItem } from '../services/firebase/db';

const SharedListItem = ({ item, listId, currentUserId, itemId }) => {
  const handleClaimToggle = async (e) => {
    try {
      if (e.target.checked) {
        await claimListItem(listId, itemId, currentUserId);
      } else {
        await unclaimListItem(listId, itemId);
      }
    } catch (error) {
      console.error('Error toggling claim:', error);
    }
  };

  const isClaimedByCurrentUser = item.claimedBy === currentUserId;
  const isClaimed = Boolean(item.claimedBy);

  return (
    <div className={`bg-gray-50 rounded-lg p-4 relative ${isClaimed ? 'opacity-75' : ''}`}>
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <div className="flex items-center gap-3">
            {/* Checkbox for claiming items */}
            <input
              type="checkbox"
              checked={isClaimed}
              onChange={handleClaimToggle}
              className="h-5 w-5 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
              disabled={isClaimed && !isClaimedByCurrentUser}
            />
            <h3 className="font-medium text-gray-900">{item.name}</h3>
          </div>
          <div className="mt-1 text-sm text-gray-500 ml-8">
            <p>Category: {item.category}</p>
            {item.category === 'Clothing' && (
              <p>Size: {item.size} | Color: {item.color}</p>
            )}
            {item.notes && (
              <p className="mt-1 text-gray-600">{item.notes}</p>
            )}
            {item.link && (
              <a 
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-2 inline-flex items-center text-purple-600 hover:text-purple-700 hover:underline"
              >
                View Item
                <ExternalLink className="h-4 w-4 ml-1" />
              </a>
            )}
          </div>
        </div>

        {isClaimed && (
          <div className="absolute top-4 right-4 flex items-center">
            <div className={`
              inline-flex items-center px-3 py-1 rounded-full text-sm
              ${isClaimedByCurrentUser 
                ? 'bg-green-100 text-green-800' 
                : 'bg-purple-100 text-purple-600'}
            `}>
              <Check className="h-4 w-4 mr-1" />
              {isClaimedByCurrentUser ? "You've Bought This" : "Someone Else Bought This"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedListItem;