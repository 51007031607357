import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { User, Save } from 'lucide-react';
import { fetchArchivedLists, fetchSharedArchivedLists } from '../services/firebase/db';
import ListCard from './ListCard';

const Settings = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const [showArchived, setShowArchived] = useState(false);
  const [archivedLists, setArchivedLists] = useState([]);
  const [sharedArchivedLists, setSharedArchivedLists] = useState([]);

    useEffect(() => {
    const loadArchivedLists = async () => {
        if (showArchived) {
        const [owned, shared] = await Promise.all([
            fetchArchivedLists(currentUser.uid),
            fetchSharedArchivedLists(currentUser.uid)
        ]);
        setArchivedLists(owned);
        setSharedArchivedLists(shared);
        }
    };

    loadArchivedLists();
    }, [showArchived, currentUser]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {
        setMessage({ type: 'error', text: 'Failed to load user data' });
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setMessage({ type: '', text: '' });

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        firstName: userData.firstName,
        lastName: userData.lastName
      });
      setMessage({ type: 'success', text: 'Settings updated successfully!' });
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to update settings' });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center mb-6">
          <User className="h-6 w-6 text-purple-600 mr-2" />
          <h1 className="text-2xl font-semibold">Account Settings</h1>
        </div>

        {message.text && (
          <div className={`mb-4 p-3 rounded ${
            message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}>
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                First Name
              </label>
              <input
                type="text"
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-500"
                value={userData.firstName}
                onChange={(e) => setUserData({...userData, firstName: e.target.value})}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Last Name
              </label>
              <input
                type="text"
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-purple-500"
                value={userData.lastName}
                onChange={(e) => setUserData({...userData, lastName: e.target.value})}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              disabled
              className="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-gray-500"
              value={userData.email}
            />
          </div>

          <button
            type="submit"
            disabled={saving}
            className="flex items-center justify-center w-full md:w-auto px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {saving ? (
              'Saving...'
            ) : (
              <>
                <Save className="h-4 w-4 mr-2" />
                Save Changes
              </>
            )}
          </button>
        </form>
      </div>

                <div className="mt-8">
            <button
                onClick={() => setShowArchived(!showArchived)}
                className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
                {showArchived ? 'Hide' : 'Show'} Archived Lists
            </button>

            {showArchived && (
                <div className="mt-4 space-y-6">
                {archivedLists.length > 0 && (
                    <div>
                    <h3 className="text-lg font-medium mb-3">Archived Lists</h3>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {archivedLists.map(list => (
                        <ListCard
                            key={list.id}
                            list={list}
                            type="personal"
                            currentUserId={currentUser.uid}
                        />
                        ))}
                    </div>
                    </div>
                )}

                {sharedArchivedLists.length > 0 && (
                    <div>
                    <h3 className="text-lg font-medium mb-3">Shared Archived Lists</h3>
                    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {sharedArchivedLists.map(list => (
                        <ListCard
                            key={list.id}
                            list={list}
                            type="shared"
                            currentUserId={currentUser.uid}
                        />
                        ))}
                    </div>
                    </div>
                )}
                </div>
            )}
            </div>


    </div>
  );
};

export default Settings;