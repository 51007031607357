import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import MainLayout from './components/layout/MainLayout';
import PrivateRoute from './components/auth/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import Settings from './pages/Settings';
import CreateList from './pages/CreateList';
import EditList from './pages/EditList';  // Add this import
import List from './pages/List';
import SurpriseGifts from './pages/SurpriseGifts';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <MainLayout>
          <Routes>
            {/* Root redirect */}
            <Route path="/" element={<Navigate to="/home" replace />} />
            
            {/* Public route */}
            <Route path="/login" element={<Login />} />
            
            {/* Protected routes */}
            <Route path="/home" element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            } />
            <Route path="/create-list" element={
              <PrivateRoute>
                <CreateList />
              </PrivateRoute>
            } />
            <Route path="/list/:listId/edit" element={
              <PrivateRoute>
                <EditList />
              </PrivateRoute>
            } />
            <Route path="/list/:listId" element={
              <PrivateRoute>
                <List />
              </PrivateRoute>
            } />
            <Route path="/list/:listId/surprise" element={
              <PrivateRoute>
                <SurpriseGifts />
              </PrivateRoute>
            } />
            <Route path="/settings" element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            } />
          </Routes>
        </MainLayout>
      </Router>
    </AuthProvider>
  );
};

export default App;