import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { fetchSharedLists, fetchFriendsLists } from '../services/firebase';
import { Gift, PlusCircle, ExternalLink, Users } from 'lucide-react';
import ListCard from './ListCard';

const EmptyState = ({ type, onCreateClick }) => (
  <div className="text-center bg-white rounded-xl shadow-sm border border-gray-100 p-8">
    <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-purple-100 mb-4">
      {type === 'personal' ? (
        <Gift className="h-6 w-6 text-purple-600" />
      ) : (
        <Users className="h-6 w-6 text-purple-600" />
      )}
    </div>
    <h3 className="text-lg font-medium text-gray-900 mb-2">
      {type === 'personal' ? 'Create your first list' : 'No shared lists yet'}
    </h3>
    <p className="text-gray-500 mb-4">
      {type === 'personal'
        ? 'Start by creating a list to keep track of your gift ideas'
        : 'When friends share their lists with you, they\'ll appear here'}
    </p>
    {type === 'personal' && (
      <button
        onClick={onCreateClick}
        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
      >
        <PlusCircle className="h-5 w-5 mr-2" />
        Create List
      </button>
    )}
  </div>
);

const Home = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [myLists, setMyLists] = useState([]);
  const [friendsLists, setFriendsLists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLists();
  }, [currentUser]);

  const fetchLists = async () => {
    if (!currentUser) return;
    
    try {
      const [fetchedMyLists, fetchedFriendsLists] = await Promise.all([
        fetchSharedLists(currentUser.uid),
        fetchFriendsLists(currentUser.uid)
      ]);
      
      // Filter out lists with status 'deleted' or 'permanently_deleted' from My Lists
      const activeMyLists = fetchedMyLists.filter(list => !list.status);
      setMyLists(activeMyLists);
      setFriendsLists(fetchedFriendsLists);
    } catch (error) {
      console.error('Error fetching lists:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMyList = (listId) => {
    setMyLists(currentLists => currentLists.filter(list => list.id !== listId));
  };

  const handleDeleteSharedList = (listId) => {
    setFriendsLists(currentLists => currentLists.filter(list => list.id !== listId));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="py-8">
      {/* Welcome Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8 mb-8">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Welcome back!</h1>
            <p className="mt-1 text-gray-500">Manage your gift lists and see what your friends are wishing for.</p>
          </div>
          <Link
            to="/create-list"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700"
          >
            <PlusCircle className="h-5 w-5 mr-2" />
            Create New List
          </Link>
        </div>
      </div>

      <div className="space-y-8">
        {/* My Lists Section */}
        <section>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900">My Lists</h2>
          </div>
          {myLists.length === 0 ? (
            <EmptyState type="personal" onCreateClick={() => navigate('/create-list')} />
          ) : (
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {myLists.map(list => (
                <ListCard 
                  key={list.id} 
                  list={list} 
                  type="personal" 
                  currentUserId={currentUser.uid}
                  onDelete={handleDeleteMyList}
                />
              ))}
            </div>
          )}
        </section>

        {/* Shared Lists Section */}
        <section>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-900">Shared With Me</h2>
          </div>
          {friendsLists.length === 0 ? (
            <EmptyState type="shared" />
          ) : (
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {friendsLists.map(list => (
                <ListCard 
                  key={list.id} 
                  list={list} 
                  type="shared" 
                  currentUserId={currentUser.uid}
                  onDelete={handleDeleteSharedList}
                />
              ))}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default Home;