import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../services/firebase';
import { 
  collection, 
  addDoc,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { Plus } from 'lucide-react';
import { validateAndFormatUrl, isAmazonLink } from '../utils/amazonUtils';

const CATEGORIES = [
  'Books',
  'Clothing',
  'Electronics',
  'Games',
  'Home & Kitchen',
  'Jewelry',
  'Sports & Outdoors',
  'Toys',
  'Other'
];

const CreateList = () => {
  const { currentUser } = useAuth();
  const { listId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [linkStatus, setLinkStatus] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  
  // Form state
  const [listTitle, setListTitle] = useState('');
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({
    name: '',
    category: '',
    notes: '',
    size: '',
    color: '',
    link: ''
  });

  // Fetch existing list data if editing
  useEffect(() => {
    const fetchData = async () => {
      if (listId) {
        setIsEditing(true);
        try {
          const listDoc = await getDoc(doc(db, 'lists', listId));
          if (listDoc.exists()) {
            const listData = listDoc.data();
            setListTitle(listData.title);
            setItems(listData.items || []);
          }
        } catch (err) {
          setError('Error fetching list');
        }
      } else {
        // Set default title for new list
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setListTitle(`${userData.firstName} ${userData.lastName}'s List`);
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [listId, currentUser]);

  const handleLinkChange = (e) => {
    const link = e.target.value;
    setNewItem({ ...newItem, link });

    if (link && !isAmazonLink(validateAndFormatUrl(link))) {
      setLinkStatus('Note: Non-Amazon links will not generate commissions');
    } else {
      setLinkStatus('');
    }
  };
  
  const handleAddItem = () => {
    if (!newItem.name || !newItem.category) {
      setError('Please fill out all required fields');
      return;
    }
  
    if (newItem.category === 'Clothing' && (!newItem.size || !newItem.color)) {
      setError('Please specify size and color for clothing items');
      return;
    }
  
    const formattedLink = validateAndFormatUrl(newItem.link);
    
    const formattedItem = {
      ...newItem,
      link: formattedLink,
      id: Date.now()
    };
  
    setItems([...items, formattedItem]);
    setNewItem({
      name: '',
      category: '',
      notes: '',
      size: '',
      color: '',
      link: ''
    });
    setError('');
    setLinkStatus('');
  };

  const handleRemoveItem = (itemId) => {
    setItems(items.filter(item => item.id !== itemId));
  };

  const handleSaveList = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (isEditing) {
        // Update existing list
        await updateDoc(doc(db, 'lists', listId), {
          title: listTitle,
          items: items,
          updatedAt: serverTimestamp()
        });
      } else {
        const listRef = await addDoc(collection(db, 'lists'), {
          title: listTitle,
          items: items,
          ownerId: currentUser.uid,
          sharedWith: [currentUser.uid],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }
      navigate('/home');
    } catch (err) {
      setError('Error saving list');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-8">
      <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-6">
          {isEditing ? 'Edit List' : 'Create New List'}
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-500 rounded-lg text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSaveList} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              List Title
            </label>
            <input
              type="text"
              required
              className="w-full p-2 border border-gray-200 rounded-lg"
              value={listTitle}
              onChange={(e) => setListTitle(e.target.value)}
            />
          </div>

          <div className="border-t border-gray-200 pt-6">
            <h3 className="text-lg font-medium mb-4">Add Items</h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Item Name *
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-200 rounded-lg"
                  value={newItem.name}
                  onChange={(e) => setNewItem({...newItem, name: e.target.value})}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Category *
                </label>
                <select
                  className="w-full p-2 border border-gray-200 rounded-lg"
                  value={newItem.category}
                  onChange={(e) => setNewItem({...newItem, category: e.target.value})}
                >
                  <option value="">Select a category</option>
                  {CATEGORIES.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </div>
            </div>

            {newItem.category === 'Clothing' && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Size *
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-200 rounded-lg"
                    value={newItem.size}
                    onChange={(e) => setNewItem({...newItem, size: e.target.value})}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Color *
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-200 rounded-lg"
                    value={newItem.color}
                    onChange={(e) => setNewItem({...newItem, color: e.target.value})}
                  />
                </div>
              </div>
            )}

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Notes
              </label>
              <textarea
                className="w-full p-2 border border-gray-200 rounded-lg"
                rows="3"
                placeholder="Size, color, other specifics..."
                value={newItem.notes}
                onChange={(e) => setNewItem({...newItem, notes: e.target.value})}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Link
              </label>
              <input
                type="url"
                className="w-full p-2 border border-gray-200 rounded-lg"
                placeholder="https://..."
                value={newItem.link}
                onChange={handleLinkChange}
              />
              {linkStatus && (
                <p className="mt-1 text-sm text-gray-500">
                  {linkStatus}
                </p>
              )}
            </div>

            <button
              type="button"
              onClick={handleAddItem}
              className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 mt-5"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add Item
            </button>
          </div>

          {items.length > 0 && (
            <div className="border-t border-gray-200 pt-6">
              <h3 className="text-lg font-medium mb-4">Items in List</h3>
              <div className="space-y-3">
                {items.map((item) => (
                  <div key={item.id} className="bg-gray-50 p-4 rounded-lg">
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="font-medium">{item.name}</h4>
                        <p className="text-sm text-gray-500">Category: {item.category}</p>
                        {item.category === 'Clothing' && (
                          <p className="text-sm text-gray-500">
                            Size: {item.size} | Color: {item.color}
                          </p>
                        )}
                        {item.notes && (
                          <p className="text-sm text-gray-500 mt-1">{item.notes}</p>
                        )}
                        {item.link && (
                          <a 
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-purple-600 hover:text-purple-700 mt-1 inline-block"
                          >
                            View Item
                          </a>
                        )}
                      </div>
                      <button
                        onClick={() => handleRemoveItem(item.id)}
                        className="text-red-600 hover:text-red-700 text-sm"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/home')}
              className="px-4 py-2 text-gray-600 hover:text-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading || items.length === 0}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : (isEditing ? 'Save Changes' : 'Create List')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateList;